.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.AppHeader {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 8px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: white;
}

.AppFooter {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.PageContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0px 0px 0px;
}

.ServiceSpace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 4px;
  cursor: pointer;
  max-width: 400px;
}
